import DynamoLogo from "../../../assets/images/DynamoLogoWhite.png";
import InstagramLogo from "../../../assets/images/Instagram.png";
import GooglePlayBadge from "../../../assets/images/GooglePlayBadge.png";
import AppStoreBadge from "../../../assets/images/AppStoreBadge.png";

import "./Footer.css";
import Screen from "../../layouts/Screen/Screen";

interface FooterProps {
  containerClassName: string;
}

const Footer = ({ containerClassName }: FooterProps) => {
  return (
    <footer className={containerClassName}>
      <Screen>
        <section className="footer-content">
          <div>
            <div className="logo-container-desktop">
              <img src={DynamoLogo} />
            </div>
          </div>
          <div className="footer-app-store-container">
            <a
              className="footer-app-store-image-container"
              href="https://apps.apple.com/gb/app/dynamo-rewards/id1592129549"
              target="_blank"
              rel="noreferrer"
            >
              <img src={AppStoreBadge} />
            </a>
            <a
              className="footer-app-store-image-container"
              href="https://play.google.com/store/apps/details?id=app.dynamorewards"
              target="_blank"
              rel="noreferrer"
            >
              <img src={GooglePlayBadge} />
            </a>
          </div>
        </section>

        <div className="footer-content-divider" />

        <section className="footer-privacy-content">
          <div className="footer-privacy-contact">
            <div className="footer-privacy-social-links">
              <p>Made with ❤️ in Leeds</p>
              <a
                className="footer-privacy-social-link"
                href="https://www.instagram.com/dynamorewards/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramLogo} />
              </a>
              {/* <a className="footer-privacy-social-link" href="">
              <img src={LinkedInLogo} />
            </a> */}
            </div>
            <div className="logo-container-mobile">
              <img src={DynamoLogo} />
            </div>
          </div>

          <div>
            <div className="footer-privacy-links">
              <a href="/privacy">Privacy Policy</a>
              <a href="/term">Terms of Service</a>
              {/* <a href="">Contact us</a> */}
            </div>

            <p className="footer-privacy-copyright">
              &#169; 2024 Dynamo Rewards. All rights reserved.
            </p>
          </div>
        </section>
      </Screen>
    </footer>
  );
};

export default Footer;
