import React from "react";

import "./FeatureSection.css";

interface FeatureSectionProps {
  id?: string;
  className?: string;
  section?: string;
  heading: string;
  content: string;
  imageSource: string;
  imageAlt: string;
  imageClass?: string;
  switchOrder?: boolean;
  children?: React.ReactNode;
}

const FeatureSection = ({
  id,
  className,
  section,
  heading,
  content,
  imageSource,
  imageAlt,
  imageClass,
  switchOrder = false,
  children,
}: FeatureSectionProps) => {
  return (
    <section
      id={id}
      className={`feature-section ${
        switchOrder ? "feature-section-switchOrder" : ""
      } ${className}`}
    >
      <div className="column">
        {section && <h3 className="feature-section-subheading">{section}</h3>}
        <h2 className="feature-section-heading">{heading}</h2>
        <p className="feature-section-paragraph">{content}</p>
        {children}
      </div>

      <div className="column">
        <div
          className={`feature-section-image-container ${
            switchOrder ? "feature-section-image-container-switch" : ""
          }`}
        >
          <img
            className={`feature-section-image ${imageClass}`}
            src={imageSource}
            alt={imageAlt}
          />
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
