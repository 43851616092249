import React from "react";

import DynamoLogoSrc from "../../../assets/images/DynamoLogo.png";

import { Button } from "../../atoms";

import "./Header.css";

type Link = {
  name: string;
  href: string;
};

type HeaderProps = {
  links: Link[];
};

const Header = ({ links }: HeaderProps) => (
  <header>
    <img
      className="header-logo"
      src={DynamoLogoSrc}
      alt="Dynamo Rewards Logo"
    />

    <div className="navigation">
      {links.map((link) => (
        <a href={link.href} className="navigation-link">
          {link.name}
        </a>
      ))}
    </div>
  </header>
);

export default Header;
