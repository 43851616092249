import { FC } from "react";

import "./Landing.css";
import { Footer } from "../../components/organisms";

import Walkers from "../../assets/images/Walkers.png";
import DogWalker from "../../assets/images/DogWalker.png";
import WalkingWithShopping from "../../assets/images/WalkingWithShopping.png";
import EnvironmentalImpact from "../../assets/images/EnvironmentalImpact.png";
import OneTreePlanted from "../../assets/images/OneTreePlantedPartner.png";
import Screen from "../../components/layouts/Screen/Screen";
import { FeatureSection, Header, Hero } from "../../components/molecules";

const Landing: FC = () => {
  return (
    <>
      <Screen>
        <Header
          links={[
            { name: "Step to earn", href: "#walk" },
            { name: "Community Challenges", href: "#compete" },
            { name: "Make an impact", href: "#make_an_impact" },
          ]}
        />
        <Hero
          containerClass="home-hero-container"
          subTitle="Dynamo Rewards is a simple yet powerful app that turns your daily steps into points that you can spend in different ways."
          showDownloadButtons
        />

        <FeatureSection
          id="walk"
          className="home-feature-section"
          section="Step To Earn"
          heading="Rewards For Steps"
          content="Walking is more than just getting from one place to another; it's your ticket to rewards. Every step is tracked and turned into points. It doesn't matter if you're walking your dog, walking through the park, or heading out to do some shopping, you will be earning rewards for steps."
          imageSource={DogWalker}
          imageAlt="Lady walking her dog on the phone"
          imageClass="home-feature-section-walk-image"
        />

        <FeatureSection
          id="compete"
          className="home-feature-section"
          section="Community challenges"
          heading="Compete Against Friends"
          content="Community groups can set up their own contests and leaderboards, which is a cool way to get everyone moving more. Group members compete, trying to outdo each other with step challenges."
          imageSource={Walkers}
          imageClass="home-feature-section-walk-earn"
          imageAlt="Two people on their phone earning points for their steps"
          switchOrder
        />

        <FeatureSection
          id="earn"
          className="home-feature-section"
          section="SPEND"
          heading="Use Your Steps to Support Local Businesses"
          content="What can you do with all these points you've collected? Spend them, of course! Use your points at local businesses to enjoy a variety of rewards. Whether it's a cup of coffee from your favourite cafe, a discount at the bookstore, or a special deal at a local restaurant, your steps can literally pay off. Supporting local businesses has never felt so good."
          imageSource={WalkingWithShopping}
          imageClass="home-feature-section-walk-spend"
          imageAlt="Lady on her phone walking with shopping"
        />

        <FeatureSection
          id="make_an_impact"
          className="home-feature-section"
          section="Making a Global Impact"
          heading=" Footsteps for the Future"
          content="Your steps reach further than you think, extending their impact globally. Our partnership with One Tree Planted converts your walking points into trees planted in over 47 countries. This initiative is crucial for combating climate change, restoring forests affected by natural disasters, creating employment opportunities, helping community development, and preserving habitats for wildlife."
          imageSource={EnvironmentalImpact}
          imageClass="home-feature-section-environmental-impact"
          imageAlt="Lady on her phone walking with shopping"
          switchOrder
        >
          <img
            className="home-impact-partner"
            src={OneTreePlanted}
            alt="One Tree Planted logo"
          />
        </FeatureSection>
      </Screen>
      <Footer containerClassName="home-footer-container" />
    </>
  );
};

export default Landing;
