import GooglePlayBadge from "../../../assets/images/GooglePlayBadge.png";
import AppStoreBadge from "../../../assets/images/AppStoreBadge.png";

import "./Hero.css";

interface HeroProps {
  subTitle: string;
  showDownloadButtons?: boolean;
  containerClass?: string;
}

const Hero = ({ subTitle, showDownloadButtons, containerClass }: HeroProps) => {
  return (
    <section className={`hero ${containerClass}`}>
      <div className="hero-content">
        <h1 className="hero-content-heading">
          Walk, Earn, and <br /> Make a Difference
        </h1>
        <p className="hero-content-paragraph">{subTitle}</p>

        {showDownloadButtons && (
          <div className="hero-app-store-container">
            <a
              className="hero-app-store-image-container"
              href="https://apps.apple.com/gb/app/dynamo-rewards/id1592129549"
              target="_blank"
              rel="noreferrer"
            >
              <img src={AppStoreBadge} alt="Apple app store logo" />
            </a>
            <a
              className="hero-app-store-image-container"
              href="https://play.google.com/store/apps/details?id=app.dynamorewards"
              target="_blank"
              rel="noreferrer"
            >
              <img src={GooglePlayBadge} alt="Google play store logo" />
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default Hero;
